<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="4">
        <contact-list-card></contact-list-card>
      </v-col>
      <v-col cols="8">
        <message-list-card></message-list-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const ContactListCard = () => import("../lists/ContactListCard.vue");
const MessageListCard = () => import("../lists/MessageListCard.vue");

export default {
  components: {
    ContactListCard,
    MessageListCard
  }
};
</script>
